var app;
(function (app) {
    var timeline;
    (function (timeline) {
        'use strict';
        var TimelineController = /** @class */ (function () {
            /* @ngInject */
            TimelineController.$inject = ["$scope", "$element", "$document", "$timeout", "$q", "dataservice", "$state", "$location", "$rootScope", "rxData"];
            function TimelineController($scope, $element, $document, $timeout, $q, dataservice, $state, $location, $rootScope, rxData) {
                var _this = this;
                this.$scope = $scope;
                this.$element = $element;
                this.$document = $document;
                this.$timeout = $timeout;
                this.$q = $q;
                this.dataservice = dataservice;
                this.$state = $state;
                this.$location = $location;
                this.$rootScope = $rootScope;
                this.rxData = rxData;
                this.set_mobile = false;
                this.$onInit = function () {
                    rxData.rootScopeOnready().then(function (data) {
                        _this.pageOBJ = data;
                        console.log(' this.pageOBJ timeline', _this.pageOBJ);
                        // $timeout(() => {
                        //   $scope.$apply();
                        // },500)
                    }, function (err) {
                        _this.pageOBJ = false;
                        console.log('error on home', err);
                    });
                    _this.checkScreenSize();
                };
            }
            ;
            TimelineController.prototype.checkScreenSize = function () {
                var _t = this;
                initSize();
                $(window).resize(function () {
                    initSize(this, true);
                });
                function initSize(t, digest) {
                    if (t === void 0) { t = window; }
                    if (digest === void 0) { digest = false; }
                    var body_width = $(t).width();
                    var is_mobile = (body_width <= 767) ? true : false;
                    console.log('body_width-- ', body_width, is_mobile);
                    if (is_mobile) {
                        _t.set_mobile = true;
                        console.log('is_mobile true', _t.set_mobile, body_width);
                    }
                    else {
                        _t.set_mobile = false;
                        console.log('_t.set_mobile false ', _t.set_mobile, body_width);
                    }
                    if (digest) {
                        _t.$scope.$apply();
                    }
                }
            };
            return TimelineController;
        }());
        timeline.TimelineController = TimelineController;
        var TimelineComponent = /** @class */ (function () {
            function TimelineComponent() {
                this.restrict = 'E';
                this.controllerAs = 'vm';
                this.templateUrl = 'dist/js/app.timeline.html';
                this.controller = TimelineController;
            }
            return TimelineComponent;
        }());
        angular
            .module('app.timeline', []);
        angular
            .module('app.timeline').component('timeline', new TimelineComponent());
    })(timeline = app.timeline || (app.timeline = {}));
})(app || (app = {}));
